class SubscribeBox extends Component {
	constructor(el) {
		super(el);
		this.form = this.$find('form');
		this.addListeners();
	}

	onSubmit(e) {
		e.preventDefault();
		EventBus.getInstance().dispatchEvent('showGlobalSpinner');

		let formData = new FormData(this.form);
		formData.append('ajax', '1');
		formData.append(this.form.querySelector('[data-button-submit]').getAttribute('name'), '1');

		fetch(this.form.getAttribute('action'), {
			method: 'POST',
			body: formData,
		})
		.then(response => response.json())
		.then(data => this.onSuccess(data))
		.catch((error) => {
			console.error('Error:', error);
		}).finally(() => {
			EventBus.getInstance().dispatchEvent('hideGlobalSpinner');
		});
	}

	onSuccess(data) {
		if (data.result === true) {
			EventBus.getInstance().dispatchEvent('showBasePopup', 'newsletter-popup');
		} else {
			const event = {id: 'newsletter-error-popup', data: {title: data.title, body: this.prepareErrorMessages(data.messages)}};
			EventBus.getInstance().dispatchEvent('changePopupContent', event);
			EventBus.getInstance().dispatchEvent('showBasePopup', event.id);
		}
		if(data.remarketingCodes) {
			Util.appendToBody(data.remarketingCodes);
		}
	}

	prepareErrorMessages(/** string[] */messages) {
		if (!messages) {
			return '';
		}
		let html = '';
		messages.forEach((e) => {
			html += "<p>" + e + '</p>';
		})

        return html;
	}

	addListeners() {
		this.form.addEventListener('submit', (e) => this.onSubmit(e));
	}

}
